import React, { useCallback, useContext, useMemo } from 'react';
import { openFeedbackReportSubMenu, setCmdPaletteOpen } from 'shared/foreground/cmdPalette';
import { globalState } from 'shared/foreground/models';
import { useFocusedDocumentId } from 'shared/foreground/stateHooks/useFocusedDocument';
import {
  createBugReport,
  createFeatureRequest,
  createFeedbackRequest,
  createLinearIssue,
} from 'shared/foreground/utils/CreateLinearIssue';
import type { BaseDocument } from 'shared/types';
import { SubMenu } from 'shared/types';
import { commitId } from 'shared/utils/environment';
import {
  FeedbackCategory,
  FeedbackSubCategory,
  mapRequestCategoryToPlaceholder,
  mapRequestSubCategoryToLabel,
} from 'shared/utils/feedback';
import { shuffle } from 'shared/utils/shuffle';

import { getActionKey } from '../../utils/getActionKey';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { PaletteAction } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';

type ReportingPaletteActionMetadata = {
  // eslint-disable-next-line react/no-unused-prop-types
  category?: FeedbackCategory;
  // eslint-disable-next-line react/no-unused-prop-types
  parent?: SubMenu;
  // eslint-disable-next-line react/no-unused-prop-types
  subCategory?: FeedbackSubCategory;
};

const useClearAndCloseInputAfterAction = (action: () => void) => {
  const { setInput } = useContext(CmdInputContext);

  return useMemo(
    () => () => {
      action();
      setInput('');
      setTimeout(() => setCmdPaletteOpen(false, { userInteraction: 'unknown' }), 100);
    },
    [action, setInput],
  );
};

const ReportArticleFailAction = ({
  focused,
  docId,
}: PaletteAction & { docId: BaseDocument['id'] | null; }) => {
  const { input } = useContext(CmdInputContext);
  const action = useClearAndCloseInputAfterAction(() => {
    if (!docId || input === '') {
      return;
    }
    createLinearIssue(docId, input);
  });

  return (
    <PaletteAction focused={focused} action={action} shortcut={`${getActionKey()}+enter`}>
      Report issue
    </PaletteAction>
  );
};

const ProvideFeatureRequestAction = ({
  focused,
  subCategory,
}: PaletteAction & ReportingPaletteActionMetadata) => {
  const { input } = useContext(CmdInputContext);
  const ensureSubCategory = subCategory ?? FeedbackSubCategory.Other;
  const action = useClearAndCloseInputAfterAction(() => {
    if (input === '') {
      return;
    }
    createFeatureRequest(input, ensureSubCategory, {
      platform: 'web',
      url: window.location.href,
      commitId,
    });
  });

  return (
    <PaletteAction focused={focused} action={action} shortcut={`${getActionKey()}+enter`}>
      Submit feature suggestion
    </PaletteAction>
  );
};

const ProvideBugReportAction = ({
  focused,
  subCategory,
}: PaletteAction & ReportingPaletteActionMetadata) => {
  const { input } = useContext(CmdInputContext);
  const ensureSubCategory = subCategory ?? FeedbackSubCategory.Other;
  const action = useClearAndCloseInputAfterAction(() => {
    if (input === '') {
      return;
    }
    createBugReport(input, ensureSubCategory, { platform: 'web', url: window.location.href, commitId });
  });

  return (
    <PaletteAction focused={focused} action={action} shortcut={`${getActionKey()}+enter`}>
      Submit bug report
    </PaletteAction>
  );
};

const ReportIssueAction = ({ focused, category }: PaletteAction & ReportingPaletteActionMetadata) => {
  const { input } = useContext(CmdInputContext);
  const ensureCategory = category ?? FeedbackCategory.Bug;
  const action = useClearAndCloseInputAfterAction(() => {
    if (input === '') {
      return;
    }
    createFeedbackRequest(input, ensureCategory, undefined, {
      platform: 'web',
      url: window.location.href,
      commitId,
    });
  });

  return (
    <PaletteAction focused={focused} action={action} shortcut={`${getActionKey()}+enter`}>
      Submit issue
    </PaletteAction>
  );
};

export const ReportingPalette = (): JSX.Element => {
  const docId = useFocusedDocumentId();
  const feedbackCategory = globalState(useCallback((state) => state.cmdPalette.feedbackCategory, []));
  const feedbackSubCategory = globalState(
    useCallback((state) => state.cmdPalette.feedbackSubCategory, []),
  );

  const readableFeedbackSubCategory =
    feedbackSubCategory && mapRequestSubCategoryToLabel.get(feedbackSubCategory);
  const placeholder =
    feedbackCategory != null ? mapRequestCategoryToPlaceholder.get(feedbackCategory) : 'issue';
  const title = `${['Describe your', readableFeedbackSubCategory?.toLowerCase(), placeholder]
    .filter((p) => p != null)
    .join(' ')}`;

  if (feedbackCategory === FeedbackCategory.Bug) {
    return (
      <PaletteWrapper multiline title={title} showRecordScreenButton>
        <ProvideBugReportAction focused={false} subCategory={feedbackSubCategory} />
      </PaletteWrapper>
    );
  }

  if (feedbackCategory === FeedbackCategory.Feature) {
    return (
      <PaletteWrapper multiline title={title} showRecordScreenButton>
        <ProvideFeatureRequestAction focused={false} subCategory={feedbackSubCategory} />
      </PaletteWrapper>
    );
  }

  if (feedbackCategory === FeedbackCategory.Parsing) {
    return (
      <PaletteWrapper multiline title={title} showRecordScreenButton>
        <ReportArticleFailAction focused={false} docId={docId} />
      </PaletteWrapper>
    );
  }

  return (
    <PaletteWrapper multiline title={title} showRecordScreenButton>
      <ReportIssueAction focused={false} category={feedbackCategory} />
    </PaletteWrapper>
  );
};

const ReportAction = ({
  focused,
  label,
  parent,
  category,
  subCategory,
}: PaletteAction & ReportingPaletteActionMetadata) => {
  const ensureParent = parent ?? SubMenu.AllCommands;
  return (
    <PaletteAction
      focused={focused}
      icon={<ChevronRightIcon />}
      action={() => openFeedbackReportSubMenu(ensureParent, category, subCategory)}
    >
      {label}
    </PaletteAction>
  );
};

export const FeatureRequestPalette = (): JSX.Element => {
  const subCategories = shuffle(Array.from(mapRequestSubCategoryToLabel));
  return (
    <PaletteWrapper title="Select type of feature suggestion">
      {subCategories.map(([subCategory, label]) =>
        <ReportAction
          key={label}
          focused={false}
          label={label}
          parent={SubMenu.FeatureRequest}
          category={FeedbackCategory.Feature}
          subCategory={subCategory}
        />)}
    </PaletteWrapper>
  );
};

export const BugReportPalette = (): JSX.Element => {
  const subCategories = shuffle(Array.from(mapRequestSubCategoryToLabel));
  return (
    <PaletteWrapper title="Select type of bug report">
      {subCategories.map(([subCategory, label]) =>
        <ReportAction
          key={label}
          focused={false}
          label={label}
          parent={SubMenu.BugReport}
          category={FeedbackCategory.Bug}
          subCategory={subCategory}
        />)}
    </PaletteWrapper>
  );
};
