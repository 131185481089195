import type { GlobalTagsObject } from '../../types/tags';

export const needsToMergeTags = ({
  globalTagsObject,
  prevTagName,
  newTagName,
}: { globalTagsObject: GlobalTagsObject; prevTagName: string; newTagName: string; }): boolean => {
  return (
    Boolean(globalTagsObject[newTagName.toLocaleLowerCase()]) &&
    prevTagName.toLocaleLowerCase() !== newTagName.toLocaleLowerCase()
  );
};
